import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

import PageWrapper from "../components/PageWrapper";

import emailIcon from "../assets/image/inner-page/png/email.png";
import callIcon from "../assets/image/inner-page/png/call.png";
import addressIcon from "../assets/image/inner-page/png/address.png";
import USAFlag from "../assets/image/inner-page/png/usa.png";
import SLFlag from "../assets/image/inner-page/png/srilankan.png";
import ContactImg from "../assets/image/inner-page/png/contact-img.png";
import { emailRegex } from "../utils";

const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    const currentPath = window.location.pathname;
    const withoutSlashesPath = currentPath.replace(/^\/+|\/+$/g, "").trim();
    const newPath = `/${withoutSlashesPath}/index.html`;
    window.history.replaceState({}, "", newPath);
  }, []);

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const ContactCard = ({ type }) => {
    return (
      <div className="col-md-4 mb-10 mb-md-0">
        <div className="w-100 min-h-275 rounded d-flex align-items-center flex-column  shadow">
          <img
            width={50}
            height={type === "email" ? 38 : 50}
            src={
              type === "email"
                ? emailIcon
                : type === "call"
                ? callIcon
                : addressIcon
            }
            className="mt-10"
          />
          <p className="mt-5 mb-0 gray-text">
            {type === "email"
              ? "Email"
              : type === "call"
              ? "Call Us"
              : "Address"}
          </p>
          {type === "email" && (
            <a
              className="gray-text font-size-5"
              href="mailto:info@unicloud360.com"
            >
              info@unicloud360.com
            </a>
          )}
          {type === "call" && (
            <div className="mt-5">
              <div className="d-flex">
                <img width={30} height={30} src={USAFlag} />
                <a
                  className="font-size-5 gray-text ml-4"
                  href="tel:+1-302-409-0980"
                >
                  +1 302 409 0980
                </a>
              </div>
              <div className="d-flex">
                <img width={30} height={30} src={SLFlag} className={""} />
                <a
                  className="font-size-5 gray-text ml-4"
                  href="tel:+94 117 112 119"
                >
                  +94 117 112 119
                </a>
              </div>
            </div>
          )}
          {type === "address" && (
            <div className="mt-5 px-15">
              <p className="font-size-4 line-height-20 gray-text">
                1007 N Orange St. 4th Floor Suite #310, Wilmington, Delaware
                19801, United States
              </p>
              <p className="font-size-4 line-height-20 gray-text">
                17A, Hirana Road, Panadura 12500, Sri Lanka
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const IconCard = (props) => {
    return (
      <div className="rounded min-w-63 h-100 w-75 shadow d-flex justify-content-center align-items-center">
        <img
          className="m-8"
          width={25}
          height={props.img === emailIcon ? 20 : 25}
          src={props.img}
        />
      </div>
    );
  };
  // const handleFields = e => {
  //   this.setState({ [e.target.name]: e.target.value, successMsg: '' })
  // };

  const handleForm = () => {
    const obj = {
      Full_Name: formState.name,
      Email: formState.email,
      Subject: formState.subject,
      Message: formState.message,
    };
    if (!emailRegex.test(formState.email)) {
      messageHandler("INFO");
    } else {
      axios
        .post("https://formcarry.com/s/JaBWzvCEIx", obj, {
          headers: { Accept: "application/json" },
        })
        .then(() => {
          setFormState({ name: "", email: "", subject: "", message: "" });
          // document.getElementById("contactForm").reset();
          // that.setState({
          //     successMsg: "We received your submission"
          // });
          // document.getElementById("contactForm").reset();

          messageHandler("SUCCESS");
        })
        .catch((error) => {
          console.log(error);
          messageHandler("FAILED");
        });
    }
  };

  const messageHandler = (status) => {
    swal({
      title:
        status === "SUCCESS"
          ? "We received your submission"
          : status === "INFO"
          ? "Please enter valid email!"
          : "Connection interrupted. Please try again!",
      icon: status === "SUCCESS" ? "success" : "error",
      closeOnClickOutside: false,
      buttons: {
        dangerMode: {
          text: "Okay",
          value: "action",
          className: "okay-btn",
        },
      },
    }).then((value) => {
      switch (value) {
        case "action":
          // if (status === "SUCCESS") {
          //   navigate("/contact#ask-question");
          // }
          break;
        default:
      }
    });
  };

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Start 14 Days Free Trial
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="min-height-100vh d-flex align-items-center ">
          <div className="container pt-23 pt-md-26 pt-lg-30 pb-8 pb-md-12 pb-lg-23">
            {/* Section Title */}
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div
                  className="mb-10 mb-lg-18 text-center"
                  data-aos="fade-up"
                  data-aos-duration={300}
                  data-aos-once="true"
                >
                  <h2 className="font-size-10 title mb-7">Contact us</h2>
                  <p className="font-size-6 gray-text mb-0">
                    <span className="title">UNICLOUD360</span> can enhance
                    student success, institutional efficiency, and growth. So
                    why not call us now?
                  </p>
                </div>
              </div>
            </div>
            {/* End Section Title */}

            {/*cards*/}
            <div className="row pb-20">
              <ContactCard type={"email"} />
              <ContactCard type={"call"} />
              <ContactCard type={"address"} />
            </div>

            <div id={"ask-question"} className="row mt-20">
              <div className="col-md-6">
                <h2 className="title font-size-9 mr-30">
                  Take the first step and inquire now
                </h2>
              </div>
              <div className="col-md-6">
                <div className="row px-10">
                  <div className="col-4">
                    <IconCard img={emailIcon} />
                  </div>
                  <div className="col-4">
                    <IconCard img={callIcon} />
                  </div>
                  <div className="col-4">
                    <IconCard img={addressIcon} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-15 pb-25">
              <div className="col-md-6">
                <div className="mr-md-20 mr-0">
                  <img src={ContactImg} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end flex-column">
                <div className="pt-12">
                  <form
                    // action="https://formsubmit.co/geethanjana@gmail.com"
                    name="contact"
                    target="_blank"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleForm();
                    }}
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        value={formState.name || ""}
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Full Name"
                        id="company"
                        onChange={changeHandler}
                        required
                      />
                    </div>
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        value={formState.email || ""}
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Email Address"
                        id="email"
                        onChange={changeHandler}
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="subject"
                        value={formState.subject || ""}
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Subject"
                        id="subject"
                        onChange={changeHandler}
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <textarea
                        name="message"
                        value={formState.message || ""}
                        id="message"
                        placeholder="Type your message"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 pt-7 font-size-5 border-default-color"
                        defaultValue={""}
                        onChange={changeHandler}
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-blue-3 h-55 w-100 rounded-4"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Contact Form */}
            {/*<div className="row justify-content-center">*/}
            {/*  <div className="col-12">*/}
            {/*    /!* contact details *!/*/}
            {/*    <div*/}
            {/*      className="top-contact-info bg-default-1 max-w-540 mx-auto py-10 px-13 rounded-10"*/}
            {/*      data-aos="fade-up"*/}
            {/*      data-aos-duration={600}*/}
            {/*      data-aos-once="true"*/}
            {/*    >*/}
            {/*      <div className="row">*/}
            {/*        <div className="col-lg-6 mb-5 mb-lg-0">*/}
            {/*          <div className="border-md-right border-cornflower-blue">*/}
            {/*            <h4 className="font-size-5 text-default-color font-weight-normal mb-0">*/}
            {/*              Call us*/}
            {/*            </h4>*/}
            {/*            <a*/}
            {/*              className="font-size-7 font-weight-bold heading-default-color"*/}
            {/*              href="/#"*/}
            {/*            >*/}
            {/*              +1-394-429-3985*/}
            {/*            </a>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div className="col-lg-6 mb-5 mb-lg-0">*/}
            {/*          <div className="pl-1 pr-3">*/}
            {/*            <h4 className="font-size-5 text-default-color font-weight-normal mb-0">*/}
            {/*              Email us*/}
            {/*            </h4>*/}
            {/*            <a*/}
            {/*              className="font-size-7 font-weight-bold heading-default-color"*/}
            {/*              href="/#"*/}
            {/*            >*/}
            {/*              support@finity.com*/}
            {/*            </a>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    /!* contact details *!/*/}
            {/*    <div className="pt-12 pb-10 max-w-536 mx-auto">*/}
            {/*      <form*/}
            {/*        name="contact"*/}
            {/*        method="POST"*/}
            {/*        data-netlify="true"*/}
            {/*        data-netlify-honeypot="bot-field"*/}
            {/*        data-aos="fade-up"*/}
            {/*        data-aos-duration={1100}*/}
            {/*        data-aos-once="true"*/}
            {/*      >*/}
            {/*        <input type="hidden" name="form-name" value="contact" />*/}
            {/*        /!* Company Name *!/*/}
            {/*        <div className="form-group mb-7 position-relative">*/}
            {/*          <input*/}
            {/*            type="text"*/}
            {/*            name="name"*/}
            {/*            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"*/}
            {/*            placeholder="John Doe"*/}
            {/*            id="company"*/}
            {/*            required*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*        /!* Email *!/*/}
            {/*        <div className="form-group mb-7 position-relative">*/}
            {/*          <input*/}
            {/*            type="email"*/}
            {/*            name="email"*/}
            {/*            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"*/}
            {/*            placeholder="Email Address"*/}
            {/*            id="email"*/}
            {/*            required*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*        /!* Company Name *!/*/}
            {/*        <div className="form-group mb-7 position-relative">*/}
            {/*          <input*/}
            {/*            type="text"*/}
            {/*            name="subject"*/}
            {/*            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"*/}
            {/*            placeholder="Subject"*/}
            {/*            id="subject"*/}
            {/*            required*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*        /!* Company Name *!/*/}
            {/*        <div className="form-group mb-7 position-relative">*/}
            {/*          <textarea*/}
            {/*            name="message"*/}
            {/*            id="message"*/}
            {/*            placeholder="Type your message"*/}
            {/*            className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 pt-7 font-size-5 border-default-color"*/}
            {/*            defaultValue={""}*/}
            {/*            required*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*        <div className="button">*/}
            {/*          <button*/}
            {/*            type="submit"*/}
            {/*            href="/#"*/}
            {/*            className="btn btn-blue-3 h-55 w-100 rounded-4"*/}
            {/*          >*/}
            {/*            Send*/}
            {/*          </button>*/}
            {/*        </div>*/}
            {/*      </form>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
